<template>
  <div
    class="text-input"
    :class="{
      'border-b border-white': !formInput,
      'px-16 flex-1': formInput && !newsletterModal,
    }"
  >
    <div :class="{ 'border-b-2 border-grey800 ': newsletterModal }">
      <input
        :id="id"
        :value="input"
        class="input form-control font-normal"
        :class="{
          'border-warning focus:border-warning': !!error,
          'text-grey200': !formInput,
          'text-grey600 focus:text-grey800 valid:text-grey800 w-full': formInput
        }"
        required="required"
        :type="type"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :autofocus="autofocus"
        @input="(e) => (input = e.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('enter-submit')"
        @keypress="numberInput($event)"
      />
      <label
        :for="id"
        class="flex absolute uppercase cursor-text select-none meta-info-text w-full pr-25 pr-28"
        :class="{
          'form-control-placeholder': !formInput,
          'form-input-control-placeholder': formInput,
          'text-grey200': !error && !formInput && !input,
          'text-grey600': !error && formInput && !input && !newsletterModal,
          'text-grey800': !error && !formInput && !input && newsletterModal,
          'text-error': !!error,
          'text-warning': !!warning,
          'has-input text-grey200': !!input && !error && !formInput,
          'has-input text-grey800': !!input && !error && formInput,
          'has-input text-error': !!input && !!error,
        }"
      >
        <fa
          v-if="error"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-circle']"
        ></fa>
        <fa
          v-else-if="warning"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-triangle']"
        ></fa>
        <span v-if="warning">
          {{ `${placeholder} - ${warning}` }}
        </span>
        <span v-else class="w-full">
          {{ !!error ? `${placeholder} - ${error}` : placeholder }}
        </span>
      </label>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import debounce from '~/util/debounce.js';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    warning: {
      type: String,
      required: false,
      default: '',
    },
    formInput: {
      type: Boolean,
      default: true,
    },
    newsletterModal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    initValue: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    delaySubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: 524288,
    },
    autocomplete: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    numberOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputMode: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      input: '',
      hasFocus: false,
      submitInputDebounced: () => {},
    };
  },
  watch: {
    input() {
      this.submitInputDebounced();
    },
    initValue(newVal) {
      this.input = newVal;
    },
  },
  created() {
    this.submitInputDebounced = debounce(
      this.submitInput,
      this.delaySubmit ? 800 : 300
    );
    this.input = this.initValue;
  },
  methods: {
    submitInput() {
      this.$emit('submitInput', this.input, this.id);
    },
    clear() {
      this.input = '';
    },
    numberInput(event: any) {
      if (this.numberOnly) {
        const keyCode = event.keyCode ? event.keyCode : event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 43) {
          event.preventDefault();
        }
      }
    },
  },
});
</script>

<style scoped>
.text-input {
  @apply relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus, {
    transition: all 5000s ease-in-out 0s;
  }
}

.form-control {
  height: 56px;
  padding-top: 16px;
  padding-bottom: 4px;
  appearance: none;
  border: 0;
  outline: none;
  background: transparent;

  @apply text-base w-full;
}

.form-input-control-placeholder.text-error:not(.has-input) {
  @apply top-1/2 transform -translate-y-1/2;
}

.form-control-placeholder,
.form-input-control-placeholder {
  transition: all 200ms;
  top: 20px;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
}

.form-control:focus + .form-input-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
  top: 20px;
}

.has-input {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
  line-height: 14px;
}

</style>
